import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyHeading from '#app/components/typography/plenty-heading'

export const HelpLink = ({ to, label }: { to: string; label: string }) => (
	<Link to={to} className="group w-fit cursor-pointer">
		<PlentyBody
			size="md"
			className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50 "
		>
			{label}
		</PlentyBody>
	</Link>
)

const QuickHelpLinks = () => {
	const { t } = useTranslation('footer')

	return (
		<div className="flex flex-col gap-4">
			<PlentyHeading as="h7">{t('get_help')}</PlentyHeading>
			<div className="flex flex-col gap-2">
				<HelpLink to="/faq" label={t('faq')} />
				<HelpLink
					to="/click-and-collect-and-delivery"
					label={t('shipping_&_delivery')}
				/>
				<HelpLink to="/returns-and-refunds" label={t('returns_&_refunds')} />
			</div>
		</div>
	)
}

export default QuickHelpLinks
